import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="Full Stack Senior Engineer - Software Careers | MCRO"
    description={`Sr. Full-Stack Developer, focusing on UI, with experience building full-stack applications utilizing 
    current JavaScript libraries as well as guiding and mentoring junior developers. This will be a dynamic position 
    across the organization that will entail both R&D with new technology and development in the existing codebase.`}
    metaContent="react, node, full stack, web, back-end, javascript, front-end, redux, es6, es7"
    id="full-stack"
    jobTitle="Full Stack Javascript Engineer"
    jobDescription={`Sr. Full-Stack Developer, focusing on UI, with experience building full-stack
    applications utilizing current JavaScript libraries as well as guiding and mentoring junior developers.
    This will be a dynamic position across the organization that will entail both R&D with new technology
    and development in the existing codebase. Experience using ES6, modern SPA frameworks, SCSS, Node,
    and strong foundational JavaScript with the ability to learn different technologies and languages.
    Rapid prototyping experience also required.`}
    mailHref="mailto:jobs@mcro.tech?subject=React-Native Job Application"
    responsibilities={[
      'Will work with a variety of UI focused development teams',
      'May move between teams as needed to fill knowledge gaps',
      'Mentor junior developers',
      'Translate between different SPA frameworks as needed',
      'Rapid development of UI prototypes',
      'Ability to work within a team or individually as required'
    ]}
    techSkills={[
      'Expert Level Knowledge of ES6, JavaScript, HTML, CSS',
      'Expert Level Knowledge of at least one JavaScript framework (React/ React Native, Vue, Angular, Ionic, etc),'
      + ' knowledge of multiple JavaScript Frameworks a plus',
      'Expert Level of CSS Preprocessors and patterns, including SCSS & OOCSS',
      'Solid foundational experience with: JavaScript, Node.js (plus Express, Hapi, etc), HTML, and CSS',
      'Strong knowledge of NodeJS (plus a middleware such as Express, Hapi, etc)',
      'Strong Knowledge of 508 Compliance/Web Accessibility',
      'Ability to quickly prototype MVPs as requested by business partners'
    ]}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj Napoca-Office',
      'Private healthcare insurance for employees'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query fullStackCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
